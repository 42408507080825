<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        v-if="order"
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          ['Customer (' + customer.company.name + ')', '/customer/' + customer.customerId],
          ['Sales Dashboard', '/customer/' + customer.customerId + '/sales-dashboard'],
          ['Order ' + order.customerCounter],
        ]"
      />
    </template>
    <template #header_page_title>
      <div class="flex items-center content-center justify-center">
        <span v-if="order">{{ order.customerCounter }}</span>
        <span v-else class="invisible">-</span>
      </div>
    </template>
    <template #header_page_actions>
      <div v-if="order" class="flex gap-5 pr-3 md:px-10 items-center">
        <div
          v-if="$refs.orderComponent.orderPaymentStatus === enums.PaymentStatus.AWAITING && !loading && order.finalised"
          class="flex items-center gap-3"
        >
          <strong class="p-2 bg-gray-400 rounded-md text-white flex-shrink-0">Payment Pending</strong>
          <select
            v-model="paymentStatus"
            class="border border-solid border-gray-400 w-full p-2 hidden xs:inline"
            @change="updatePaymentStatus(paymentStatus, 'Pending')"
          >
            <option value="" disabled selected>Update Payment Status</option>
            <option :value="0">Not Required</option>
            <option :value="1" disabled>Awaiting</option>
            <option :value="2">Failed</option>
            <option :value="3">Success</option>
          </select>
        </div>
        <div
          v-else-if="$refs.orderComponent.orderPaymentStatus === enums.PaymentStatus.FAILED && !loading && order.finalised"
          class="flex items-center gap-3"
        >
          <strong class="p-2 bg-red-400 rounded-md text-white flex-shrink-0">Payment Failed</strong>
          <select
            v-model="paymentStatus"
            class="border border-solid border-gray-400 w-full p-2 hidden xs:inline"
            @change="updatePaymentStatus(paymentStatus, 'Failed')"
          >
            <option value="" disabled selected>Update Payment Status</option>
            <option :value="0">Not Required</option>
            <option :value="1">Awaiting</option>
            <option :value="2" disabled>Failed</option>
            <option :value="3">Success</option>
          </select>
        </div>
        <div v-else-if="$refs.orderComponent.orderPaymentStatus === enums.PaymentStatus.SUCCESS">
          <strong class="p-2 bg-green-400 rounded-md text-white">Payment Successful</strong>
        </div>
        <div
          v-if="customer.customer.salesSectorType === enums.salesSectorType.RETAIL"
          class="hidden xs:block"
        >
          <span class="font-medium mr-2">Sales Sector</span>
          <span class="text-brand-primary whitespace-no-wrap"> Retail </span>
        </div>
        <div class="hidden xs:block">
          <span class="font-medium mr-2">Reference</span>
          <span class="text-brand-primary whitespace-no-wrap"> {{ order.reference }} </span>
        </div>
        <div>
          <span class="font-medium mr-2">Status</span>
          <span class="text-brand-primary" v-text="$refs.orderComponent.statusText"></span>
        </div>
      </div>
    </template>
    <OrderComponent
      ref="orderComponent"
      :customer="customer"
      :show-release-to-portal-or-admin-button="showReleaseToPortalOrAdminButton"
      :cancellation-available="cancellationAvailable"
      breakdown="Price"
      @setOrder="setOrder"
      @setCustomerId="setCustomerId"
      @setVisibility="setVisibility"
      @redirectToCheckout="redirectToCheckout"
      @redirectToBasket="redirectToBasket"
      @redirectCancel="redirectCancel"
    >
      <template #download_survey>
        <div
          v-if="!order.finalised &&
            customer &&
            customer.customer.salesSectorType === enums.salesSectorType.RETAIL &&
            $refs.orderComponent.order.jobs[0].items.length > 0"
          class="flex-1"
        >
          <a
            class="btn-action whitespace-no-wrap font-medium flex justify-between w-full p-3 px-6"
            @click="$refs.orderComponent.showSurveyPdf()"
          >
            Download Survey Sheet
            <i class="fa fa-file-download text-sm"></i>
          </a>
        </div>
      </template>
      <template #release_to_portal>
        <a
          v-if="order && !order.visibleToPortal && order.visibleToPortal !== undefined"
          class="btn-action whitespace-no-wrap font-medium flex justify-between w-full p-3 px-6"
          @click.prevent="$refs.orderComponent.toggleVisibility()"
        >
          <span>Release To Portal</span>
          <i class="fa fa-eye text-sm"></i>
        </a>
      </template>
      <template #surveyor>
        <ContractRolesDropdown
          v-if="$refs.orderComponent && $refs.orderComponent.job"
          class="flex-1"
          :contract-id="$route.params.id"
          :job-key="$refs.orderComponent.job.jobKey"
          :current-staff-id="$refs.orderComponent.job.surveyorId"
          placeholder="Surveyor"
          :role="16"
        />
      </template>
      <template #fitter>
        <ContractRolesDropdown
          v-if="$refs.orderComponent && $refs.orderComponent.job"
          class="flex-1"
          :contract-id="$route.params.id"
          :job-key="$refs.orderComponent.job.jobKey"
          :current-staff-id="$refs.orderComponent.job.fitterId"
          placeholder="Fitter"
          :role="32"
        />
      </template>
      <template #sales-person>
        <ContractRolesDropdown
          v-if="$refs.orderComponent && $refs.orderComponent.job"
          class="flex-1"
          :contract-id="$route.params.id"
          :job-key="$refs.orderComponent.job.jobKey"
          :current-staff-id="$refs.orderComponent.job.salesContactId"
          placeholder="Sales"
          :role="8"
        />
      </template>
      <template
        v-if="customer && customer.customer.salesSectorType === enums.salesSectorType.RETAIL"
        #consumer_details
      >
        <div key="consumer-details" data-index="0" class="bg-white p-3 border">
          <consumer-details
            v-if="$refs.orderComponent && $refs.orderComponent.job"
            :consumer="$refs.orderComponent.order.consumer"
            :read-only="$refs.orderComponent.order.finalised"
            @edit="$refs.orderComponent.edit_consumer_open = true"
          >
          </consumer-details>
        </div>
      </template>
      <template #download_history>
        <DownloadHistory :customer="customer"></DownloadHistory>
      </template>
    </OrderComponent>
  </touch-layout>
</template>

<script>
import OrderComponent from '@/components/shared/Order.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import ContractRolesDropdown from '@/components/shared/ContractRolesDropdown.vue';
import ConsumerDetails from '@/components/shared/job/ConsumerDetails.vue';
import DownloadHistory from '@/components/business/customer/DownloadHistory.vue';

export default {
  components: {
    OrderComponent,
    Breadcrumbs,
    ContractRolesDropdown,
    ConsumerDetails,
    DownloadHistory
  },
  props: {
    customer: Object
  },
  data() {
    return {
      order: undefined,
      visibilityOptions: undefined,
      paymentStatus: '',
      loading: false,
    };
  },
  computed: {
    showReleaseToPortalOrAdminButton() {
      return this.order && !this.order.visibleToPortal && this.order.visibleToPortal !== undefined;
    },
    cancellationAvailable() {
      return (
        this.order &&
        (this.order.orderStatus !== window.enum.orderStatus.CANCELLED ||
          (this.order.orderStatus !== window.enum.orderStatus.PLACED &&
            this.order.orderStatus !== window.enum.orderStatus.CANCELLED))
      );
    },
    paymentStatusEnums() {
      return Object.keys(this.enums.PaymentStatus).map((key) => [
        key,
        this.enums.PaymentStatus[key],
      ]);
    },
  },
  methods: {
    setOrder(order) {
      this.order = order;
    },
    async setVisibility() {
      const res = await window.touch.contractUpdateVisibility(this.order.id, {
        visibleToPortal: !this.order.visibleToPortal,
      });
      if (res.status === 200) {
        this.order.visibleToPortal = !this.order.visibleToPortal;
        window.alertBox.fire('Order is now shown to portal');
      }
    },
    async setCustomerId(customerId) {
      await this.$store.dispatch('basket/setCustomerId', customerId);
    },
    redirectToCheckout() {
      this.routerPush(`/customer/${this.$route.params.customerId}/sales-dashboard/checkout`);
    },
    redirectToBasket() {
      this.routerPush(`/customer/${this.$route.params.customerId}/sales-dashboard/basket`);
    },
    redirectCancel() {
      this.$router.go();
    },
    async updatePaymentStatus(paymentStatus, status) {
      this.alertBox()
        .fire({
          title: 'Save Changes',
          html: `Are you sure you want to update payment status from <strong>${status.toUpperCase()}</strong> to <strong>${this.paymentStatusEnums[paymentStatus][0]}</strong>?<br>This action cannot be undone.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            await window.touch.UpdatePaymentStatus(this.$route.params.id, paymentStatus);
            this.order = await this.$store.dispatch('touch/loadOrder', {
              customerId: this.$route.params.customerId,
              id: this.$route.params.id,
            });
            this.$refs.orderComponent.orderPaymentStatus = this.order.orderPaymentStatus;
            this.loading = false;
          }
          this.paymentStatus = '';
        });
    },
  },
};
</script>
