import Quotes from '@/views/Portal/Quotes.vue';
import TouchPortalHome from '@/views/Portal/Home.vue';

import NewQuote from '@/views/Shared/NewQuote.vue';
import NewOrder from '@/views/Shared/NewOrder.vue';
import ShowEnquiries from '@/views/Portal/ShowEnquiries.vue';
import EnquiryShow from '@/views/Portal/EnquiryShow.vue';
import Order from '@/views/Portal/Order.vue';
import Quote from '@/views/Portal/Quote.vue';
import Basket from '@/views/Portal/Basket.vue';
import Checkout from '@/views/Portal/Checkout.vue';
import ChooseProduct from '@/views/Portal/ChooseProduct.vue';
import Designer from '@/views/Portal/Designer.vue';
import SwitchDesign from '@/views/Portal/SwitchDesign.vue';
import Enquiries from '@/views/Portal/Enquiries.vue';
import Orders from '@/views/Portal/Orders.vue';
import Marketing from '@/views/Portal/Marketing.vue';

import TouchStore from '@/views/Portal/TouchStore.vue';
import TouchStoreMoreBenefits from '@/views/Portal/TouchStore/MoreBenefits.vue';

import BrandedModeSetup from '@/views/Portal/BrandedModeSetup.vue';
import BrandedModeSetupWebsite from '@/views/Portal/BrandedModeSetup/Website.vue';
import BrandedModeSetupCompany from '@/views/Portal/BrandedModeSetup/Company.vue';
import BrandedModeSetupCustomStyles from '@/views/Portal/BrandedModeSetup/CustomStyles.vue';
import BrandedModeSetupPricing from '@/views/Portal/BrandedModeSetup/Pricing.vue';
import BrandedModeSetupPricingList from '@/views/Portal/BrandedModeSetup/Pricing/PricingList.vue';
import BrandedModeSetupProducts from '@/views/Portal/BrandedModeSetup/Products.vue';

import LoadContract from '@/views/Portal/LoadContract.vue';

import MyAccount from '@/views/Portal/MyAccount.vue';
import MyAccountStaffAndLicenses from '@/views/Portal/MyAccount/StaffAndLicenses.vue';
import MyAccountAddressesAndDeliveryDepots from '@/views/Portal/MyAccount/AddressesAndDeliveryDepots.vue';
import MyAccountTouchConnectSetup from '@/views/Portal/MyAccount/TouchConnectSetup.vue';
import MyAccountTouchMyPrices from '@/views/Portal/MyAccount/MyPrices.vue';
import MyAccountCustomStyles from '@/views/Portal/MyAccount/CustomStyles.vue';
import MyAccountTouchHiddenArticles from '@/views/Portal/MyAccount/HiddenArticles.vue';

import News from '@/views/Shared/News.vue';
import NewsShow from '@/views/Shared/News/Show.vue';

import VisualiserUpload from '@/views/Shared/Visualiser.vue';
import VisualiserPlace from '@/views/Shared/Visualiser/Place.vue';

import PaymentSuccess from '@/views/Portal/Payment/Success.vue';
import PaymentFailed from '@/views/Portal/Payment/Failed.vue';

import store from '../../store';

const routes = [
  {
    path: '/load-contract/:contractId/:jobId',
    name: 'Load A Contract',
    component: LoadContract,
    meta: {},
  },
  {
    path: '/portal',
    name: 'Portal Dashboard',
    component: TouchPortalHome,
    meta: { active: 'home' },
  },
  {
    path: '/quotes',
    name: 'Quotes',
    meta: { showSearch: true, active: 'quotes' },
    component: Quotes,
    beforeEnter: (to, from, next) => {
      if (store.state.feature.isViewQuotesEnabled) {
        next();
      } else {
        next({ name: 'Portal Dashboard' });
      }
    },
  },
  {
    path: '/quote/:id',
    name: 'Quote',
    meta: { active: 'quotes' },
    component: Quote,
  },
  {
    path: '/quote/:id/:jobId',
    redirect: '/quote/:id',
  },
  {
    path: '/enquiry/:id',
    name: 'Enquiry',
    meta: { active: 'enquiries' },
    component: ShowEnquiries,
  },
  {
    path: '/enquiry/:id/:jobId',
    name: 'Enquiry Job',
    meta: { active: 'enquiries' },
    component: EnquiryShow,
  },
  {
    path: '/basket',
    name: 'Basket',
    meta: { showSidebar: false, active: 'designer' },
    component: Basket,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    meta: { showSidebar: false, active: 'designer' },
    component: Checkout,
  },
  {
    path: '/choose-product',
    name: 'Choose Product',
    meta: { showSidebar: false, active: 'designer' },
    component: ChooseProduct,
  },
  {
    path: '/new-quote',
    name: 'New Quote',
    meta: { showSidebar: false, active: 'designer' },
    component: NewQuote,
    beforeEnter: (to, from, next) => {
      if (store.state.feature.isCreateQuoteEnabled) {
        next();
      } else {
        next({ name: 'Portal Dashboard' });
      }
    },
  },
  {
    path: '/new-order',
    name: 'New Order',
    meta: { showSidebar: false, active: 'designer' },
    component: NewOrder,
    beforeEnter: (to, from, next) => {
      if (store.state.feature.isCreateOrderEnabled) {
        next();
      } else {
        next({ name: 'Portal Dashboard' });
      }
    },
  },
  {
    path: '/design/:id',
    name: 'Designer',
    meta: { showSidebar: false, active: 'designer' },
    component: Designer,
    props: true,
    beforeEnter: (to, from, next) => {
      if (from.name === 'Switch Bay' && typeof to.query.usePreviousView === 'undefined') {
        const newTo = to;
        newTo.query.usePreviousView = window.enum.imageType.PLAN;
        next(newTo);
      }
      next();
    },
  },
  {
    path: '/switch-design/:id/:component',
    name: 'Switch Bay',
    meta: { showSidebar: false, active: 'designer' },
    component: SwitchDesign,
  },
  {
    path: '/network-enquiry',
    name: 'Enquiries',
    meta: { showSearch: true, active: 'enquiries' },
    component: Enquiries,
  },
  {
    path: '/network-enquiry/:id',
    name: 'View Enquiry',
    meta: { active: 'network-enquiries' },
    component: ShowEnquiries,
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: { showSearch: true, active: 'orders' },
    component: Orders,
    beforeEnter: (to, from, next) => {
      if (store.state.feature.isViewOrdersEnabled) {
        next();
      } else {
        next({ name: 'Portal Dashboard' });
      }
    },
  },
  {
    path: '/order/:id',
    name: 'Order',
    meta: { active: 'orders' },
    component: Order,
  },
  {
    path: '/order/:id/:jobId',
    redirect: '/order/:id',
  },
  {
    path: '/marketing',
    name: 'Downloads',
    meta: { showSearch: true, active: 'marketing', shared: true },
    component: Marketing,
  },
  {
    path: '/my-account',
    name: 'My Account',
    meta: { active: 'customer' },
    redirect: '/my-account/details',
    beforeEnter: (to, from, next) => {
      if (store.state.auth.admin === true) {
        next();
      } else {
        next({ name: 'Portal Dashboard' });
      }
    },
  },
  {
    path: '/my-account/details',
    name: 'Portal Customer Details',
    meta: { active: 'customer' },
    component: MyAccount,
  },
  {
    path: '/my-account/staff',
    name: 'My Staff',
    meta: { active: 'customer' },
    component: MyAccountStaffAndLicenses,
  },
  {
    path: '/my-account/addresses',
    name: 'Addresses',
    meta: { active: 'customer' },
    component: MyAccountAddressesAndDeliveryDepots,
  },
  {
    path: '/my-account/touch-connect',
    name: 'Touch Connect Setup',
    meta: { active: 'touch-connect' },
    component: MyAccountTouchConnectSetup,
  },
  {
    path: '/my-account/my-prices',
    name: 'My Prices',
    meta: { active: 'my-prices', showSearch: true },
    component: MyAccountTouchMyPrices,
  },
  {
    path: '/my-account/hidden-articles',
    name: 'Hidden Articles',
    meta: { active: 'hidden-articles' },
    component: MyAccountTouchHiddenArticles,
  },
  {
    path: '/my-account/custom-styles',
    name: 'Custom Styles',
    meta: { active: 'custom-styles' },
    component: MyAccountCustomStyles,
  },
  {
    path: '/touch-store',
    name: 'Touch Store',
    meta: { active: 'touch-store' },
    component: TouchStore,
  },
  {
    path: '/touch-store/more-benefits',
    name: 'More Benefits',
    meta: { active: 'more-benefits' },
    component: TouchStoreMoreBenefits,
  },
  {
    path: '/setup',
    name: 'No Branded Mode License Found',
    meta: { active: 'setup' },
    component: BrandedModeSetup,
    beforeEnter: (to, from, next) => {
      if (store.state.user.customer.eCommerceIntegration.enabled) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'No Branded Mode license found'
        });
        next('/');
      }
    }
  },
  {
    path: '/setup/company',
    name: 'Branded Mode Setup',
    meta: { active: 'setup' },
    component: BrandedModeSetupCompany,
    beforeEnter: (to, from, next) => {
      if (store.state.user.customer.eCommerceIntegration.enabled) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'No Branded Mode license found'
        });
        next('/');
      }
    }
  },
  {
    path: '/setup/custom-styles',
    name: 'Branded Mode Setup',
    component: BrandedModeSetupCustomStyles,
    beforeEnter: (to, from, next) => {
      if (store.state.user.customer.eCommerceIntegration.enabled) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'No Branded Mode license found'
        });
        next('/');
      }
    }
  },
  {
    path: '/setup/products',
    meta: { active: 'setup' },
    name: 'Branded Mode Setup',
    component: BrandedModeSetupProducts,
    beforeEnter: (to, from, next) => {
      if (store.state.user.customer.eCommerceIntegration.enabled) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'No Branded Mode license found'
        });
        next('/');
      }
    }
  },
  {
    path: '/setup/prices',
    name: 'Branded Mode Setup',
    meta: { active: 'branded-prices' },
    component: BrandedModeSetupPricing,
    beforeEnter: (to, from, next) => {
      if (store.state.user.customer.eCommerceIntegration.enabled) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'No Branded Mode license found'
        });
        next('/');
      }
    },
    children: [
      {
        path: '/',
        name: 'Prices',
        meta: { active: 'branded-settings-prices' },
        component: BrandedModeSetupPricingList,
      },
    ],
  },
  {
    path: '/setup/website',
    meta: { active: 'setup' },
    name: 'Branded Mode Setup',
    component: BrandedModeSetupWebsite,
    beforeEnter: (to, from, next) => {
      if (store.state.user.customer.eCommerceIntegration.enabled) {
        next();
      } else {
        window.alertBox.fire({
          title: 'Redirected Home',
          text: 'No Branded Mode license found'
        });
        next('/');
      }
    },
    children: [
      {
        path: 'settings',
        redirect: '/setup/website',
      },
    ],
  },
  {
    path: '/news',
    name: 'News',
    meta: { showSearch: true, active: 'news', shared: true },
    component: News,
  },
  {
    path: '/news/category/:categoryId',
    name: 'News',
    meta: { active: 'news', shared: true },
    component: News,
  },
  {
    path: '/news/:id',
    name: 'News Post',
    meta: { active: 'news', shared: true },
    component: NewsShow,
  },
  {
    path: '/visualiser',
    name: 'visualiser-portal',
    meta: { showSidebar: false, vendorLayout: true, shared: true  },
    component: VisualiserUpload,
    beforeEnter: (to, from, next) => {
      store.state.user.freshdeskWidgetEnabled = false;
      next();
    },
  },
  {
    path: '/visualiser/place',
    name: 'visualiser-place-portal',
    meta: { showSidebar: false, vendorLayout: true, shared: true  },
    component: VisualiserPlace,
    beforeEnter: (to, from, next) => {
      store.state.user.freshdeskWidgetEnabled = false;
      next();
    },
  },
  {
    path: '/payment/:contractId/success',
    name: 'Payment Sucesss',
    component: PaymentSuccess,
  },
  {
    path: '/payment/:contractId/cancelled',
    name: 'Payment Failed',
    component: PaymentFailed,
  },
];

export default routes.map((route) => {
  const meta = { ...route.meta, portalRoute: true };
  return { ...route, meta };
});
