<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs :routes="[['Dashboard', '/'], ['Dealer Network Mode Setup']]" />
    </template>
    <template #header_page_title>
      <div class="flex items-center">
        <span>
          <span class="hidden xs:inline">Welcome to your</span>
          <span class="text-green-600"> {{ setupMode }}</span>
          <span class="hidden xs:inline"> account, {{ user.firstName }}</span>
        </span>
        <HelpPoint class="ml-3" freshdesk-article-id="101000455583" />
      </div>
    </template>
    <DealerNetworkSetup
      customer_items_setup_url="/dealer-network-setup/customer-items"
      custom_styles_url="/dealer-network-setup/custom-styles"
      company_setup_url="/dealer-network-setup/company"
      product_setup_url="/dealer-network-setup/products"
      website_settings_url="/dealer-network-setup/website"
      :setup-mode="setupMode"
    />
  </touch-layout>
</template>

<script>
import HelpPoint from '@/components/shared/HelpPoint.vue';
import DealerNetworkSetup from '@/components/shared/setup/Setup.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    HelpPoint,
    DealerNetworkSetup,
    Breadcrumbs,
  },
  data() {
    return {
      setupMode: 'Dealer Network Mode',
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
};
</script>
