import Touch from './touch';
import { errorCallback, successCallback } from '@/helpers/validation';

Touch.prototype.marketingCategories = async function marketingCategories() {
  return (await this.authenticatedPost('common/GetDocumentCategories')).data;
};

Touch.prototype.marketingItems = async function marketingItems(options) {
  const fileResponse = await this.authenticatedPost('common/ListDocuments', {
    CategoryId: Number(options.categoryId),
    Type: window.enum.documentType.RESOURCE_FILE,
  });

  const videoResponse = await this.authenticatedPost('common/ListDocuments', {
    CategoryId: Number(options.categoryId),
    Type: window.enum.documentType.RESOURCE_VIDEO,
  });

  return [...fileResponse.data.documents, ...videoResponse.data.documents];
};

Touch.prototype.documentDetails = async function documentDetails(id) {
  return (
    await this.authenticatedPost('common/DocumentDetails', {
      Id: Number(id),
    })
  ).data;
};

Touch.prototype.uploadDocumentForm = async function uploadDocumentForm(newDocument) {
  return this.parseResponse(
    await this.authenticatedPost('customer/UploadDocumentForm', newDocument),
    errorCallback,
    (r)  => successCallback('Save', r.data.id)
  );
};

Touch.prototype.addDocumentCategory = async function addDocumentCategory(categoryName) {
  await this.authenticatedPost('staff/AddDocumentCategory', {
    Name: categoryName,
  });
};

Touch.prototype.updateDocumentCategory = async function updateDocumentCategory(category) {
  await this.authenticatedPost('staff/UpdateDocumentCategory', {
    Id: Number(category.id),
    Name: category.name,
  });
};

Touch.prototype.deleteDocumentCategories = async function deleteDocumentCategories(categoryIds) {
  return this.authenticatedPost('staff/DeleteDocumentCategories', {
    Ids: categoryIds,
  }).catch((error) => error.response);
};

Touch.prototype.deleteMarketingDocuments = async function deleteMarketingDocuments(documentIds) {
  return this.parseResponse(
    await this.authenticatedPost('staff/DeleteDocuments', {
      Ids: documentIds,
    }),
    errorCallback,
    () => successCallback('Delete')
  )
};
