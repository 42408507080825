<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['My Account', '/manage-touch-account/company-details'],
          ['Company Details'],
        ]"
      />
    </template>
    <template #header_page_title> Company Details </template>
    <Menu></Menu>
    <loading
      :loading="false"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden"
    >
      <CompanyDetails></CompanyDetails>
      <AccountSettings :is-lead-gen="isLeadGen"></AccountSettings>
      <TaxRates v-if="!isLeadGen"></TaxRates>
      <Currencies v-if="!isLeadGen"></Currencies>
      <WorkingDays v-if="!isLeadGen" />
      <Holidays v-if="!isLeadGen" />
    </loading>
  </touch-layout>
</template>

<script>
import Menu from '@/components/business/manageTouchAccount/Menu.vue';
import AccountSettings from '@/components/business/manageTouchAccount/AccountSettings.vue';
import Holidays from '@/components/business/manageTouchAccount/holiday/Holidays.vue';
import TaxRates from '@/components/business/manageTouchAccount/taxRate/List.vue';
import Currencies from '@/components/business/manageTouchAccount/currency/List.vue';
import WorkingDays from '@/components/business/manageTouchAccount/workingDays/WorkingDays.vue';
import CompanyDetails from '@/components/business/manageTouchAccount/CompanyDetails.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Menu,
    Breadcrumbs,
    AccountSettings,
    Currencies,
    TaxRates,
    CompanyDetails,
    WorkingDays,
    Holidays,
  },
  computed: {
    isLeadGen() {
      return (
        this.$store.state.style?.fabricator?.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
  },
};
</script>
