<template>
  <portal to="portal_popup">
    <modal-window :modal_open="true" title="Account Settings" @close="$emit('close')">
      <div class="flex flex-col container">
        <div class="flex gap-5 pb-10">
          <div class="flex-1">
            <div class="flex items-center font-medium gap-2">
              Bcc order confirmations
              <Tooltip class="hidden xs:inline" :is-left="false" :width="200">
                Bcc order confirmations to fabricator when an order is confirmed
              </Tooltip>
            </div>
            <div class="flex items-center cursor-pointer" @click="sendBccEmail = !sendBccEmail">
              <div class="relative">
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                <div class="switch-toggle" :class="{ '_active': sendBccEmail }"></div>
              </div>
              <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">
                <span v-text="sendBccEmail ? 'On' : 'Off'"></span>
              </div>
            </div>
          </div>
          <div v-if="sendBccEmail" class="flex-1">
            <input
              v-model="bccEmailAddress"
              type="email"
              placeholder="Bcc Email Address"
              class="form-control resize-none"
            />
          </div>
        </div>
        <div class="text-right mt-auto flex flex-col 2xs:block">
          <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
            Cancel
          </button>
          <button class="btn-action btn-lg" @click.prevent.stop="UpdateOrganisation">
            Save Changes
          </button>
        </div>
      </div>
    </modal-window>
  </portal>
</template>

<script>
import Tooltip from '@/components/shared/Tooltip.vue';
import validate from 'validate.js';

export default {
  components: {
    Tooltip,
  },
  props: {
    organisation: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      sendBccEmail: this.organisation.sendBccEmail,
      bccEmailAddress: this.organisation.bccEmailAddress,
    };
  },
  methods: {
    async UpdateOrganisation() {
      const validationErrors = validate(
        {
          bccEmailAddress: this.bccEmailAddress,
        },
        {
          bccEmailAddress: {
            presence: { allowEmpty: !this.sendBccEmail },
            email: true,
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }


      await window.touch.UpdateOrganisation({
        Id: this.organisation.id,
        SendBccEmail: this.sendBccEmail,
        BccEmailAddress: this.bccEmailAddress,
      })

      this.$emit('close');
    },
  },
};
</script>