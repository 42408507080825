<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Dealer Network Mode Setup', '/dealer-network-setup'],
          ['Dealer Network Mode Designer'],
        ]"
      />
    </template>
    <template #header_page_title> Dealer Network Mode Designer </template>
    <Website setup="Dealer Network Designer" :customer-id="$store.state.user.customer.customerId"></Website>
  </touch-layout>
</template>

<script>
import Website from '@/components/shared/setup/Website.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Website,
    Breadcrumbs,
  },
};
</script>
