<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <slot name="header_page_breadcrumb"></slot>
    </template>
    <template #header_page_title>
      <slot name="header_page_title"></slot>
    </template>
    <slot name="menu"></slot>
    <div class="flex flex-col flex-grow">
      <portal to="portal_search">
        <SearchBox class="flex" placeholder="Search for an item to edit a price..." />
      </portal>
      <Pricing ref="pricing" class="flex flex-col flex-grow" :search="search"></Pricing>
    </div>
  </touch-layout>
</template>

<script>
import Pricing from '@/components/business/productSetup/pricing/Pricing.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    Pricing,
    SearchBox,
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    pricingComponent() {
      return this.$refs.pricing
    }
  },
  created() {
    this.$router.replace({ query: undefined }).catch(() => { });
    setTimeout(() => {
      this.$watch('$route.query', this.loadSettings);
    }, 0);
  },
  methods: {
    loadSettings() {
      this.search = this.$route.query.search || '';
      this.$nextTick(() => {
        this.$refs.pricing.loadSettings(false, true);
      });
    },
  },
};
</script>
