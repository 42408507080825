<template>
  <div class="bg-white p-6 sm:p-10 border">
    <div class="xs:flex mb-2 sm:mb-4 items-center">
      <label class="flex flex-grow">
        <div class="pr-3 hidden md:flex flex-col justify-around">
          <div>
            Pricing Search
            <info-popup
              :id="'vendor_settings_pricing:pricing_search'"
              class=""
              :info_message="'Search for keywords to quickly jump to the right line item to add your sell price.'"
              :next_id="'vendor_settings_pricing:show_hide_products'"
            ></info-popup>
          </div>
        </div>
        <div class="flex-grow">
          <div class="relative">
            <SearchBox class="flex" placeholder="Search for an item to edit a price..." />
          </div>
        </div>
      </label>
    </div>
    <div class="2xs:flex text-xs sm:text-sm">
      <div class="xs:flex mb-2 sm:mb-4 items-center mr-4">
        <span class="">
          Show/Hide products
          <info-popup
            :id="'vendor_settings_pricing:show_hide_products'"
            class=""
            :info_message="'Use the filters to turn off materials to make it easier to find the line items you are looking for. Turning off a material here will not effect the designer.'"
            :next_id="'vendor_settings_pricing:show_unpriced_items'"
          ></info-popup>
        </span>
        <div class="">
          <div class="flex -ml-2 xs:ml-0">
            <label
              v-for="filter in filters"
              :key="filter.label"
              class="border p-1 sm:p-2 ml-1 sm:ml-2 rounded flex items-center"
            >
              <div class="pr-1 sm:pr-2">{{ filter.label }}</div>
              <input v-model="filter.active" type="checkbox" /></label>
          </div>
        </div>
      </div>
      <div class="xs:flex mb-2 sm:mb-4 items-center ml-auto">
        <span class="">
        </span>
        <div class="">
          <div class="flex -ml-2 xs:ml-0">
            <label class="border p-1 sm:p-2 ml-1 sm:ml-2 rounded flex items-center">
              <div class="pr-1 sm:pr-2">Show unpriced items only (grid excluded)</div>
              <input v-model="unpricedFilterActive" type="checkbox" /></label>
          </div>
        </div>
      </div>
    </div>

    <div class="p-4 mb-4 bg-purple-200 rounded-lg">
      Please note: Pricing shows all products - <strong>not only those enabled</strong> - You don't
      need to fill in every box, ignore the ones you didn't enable.
    </div>

    <div class="flex flex-col sm:flex-row">
      <div class="mb-3 sm:mb-0 pricing-categories-sidebar">
        <div class="border border-gray-400 rounded-lg md:h-full">
          <div
            class="p-3 sm:border-none flex justify-between sm:pointer-events-none"
            :class="{ 'border-b border-gray-300': category_list_open }"
            @click="category_list_open = !category_list_open"
          >
            <h1 class="text-center text-base">Pricing Groups</h1>
            <div class="flex flex-col justify-around sm:hidden">
              <i v-show="!category_list_open" class="fal fa-chevron-down"></i>
              <i v-show="category_list_open" class="fal fa-chevron-up"></i>
            </div>
          </div>
          <div class="p-3 sm:block" :class="{ hidden: !category_list_open }">
            <ul>
              <template v-for="group in pricesInGroups">
                <li
                  v-if="group.prices.length > 0"
                  :key="group.id"
                  :class="{
                    'text-gray-500': group.prices.length === 0,
                    'bg-green-600 text-white': active_section === group.id,
                  }"
                  class="mt-1 flex justify-between bg-gray-200 rounded-lg p-2 cursor-pointer group hover:bg-green-600 hover:text-white text-xs sm:text-sm"
                  @click="(category_list_open = false), scrollToTop()"
                >
                  <div class="pr-6">{{ group.description }}</div>
                  <div class="flex">
                    <div class="text-xs">({{ group.prices.length }})</div>
                    <div class="flex flex-col justify-around pl-2">
                      <i
                        class="fa fa-caret-right opacity-0 group-hover:opacity-100"
                        :class="{ 'opacity-100': active_section === group.id }"
                      ></i>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <div class="sm:ml-3 relative pricing-category-options-list-wrapper">
        <div
          class="absolute top-0 left-0 right-0 bottom-0 overflow-y-auto border border-gray-400 rounded-lg"
          @scroll.passive="productListScrolled"
        >
          <div
            v-if="anomalies.length > 0 && populatedPricesInGroups.length === 0"
            class="flex justify-between"
          >
            <div class="flex flex-col justify-around">
              <div class="p-3">No Matching Products Found</div>
            </div>
            <div class="p-3">
              <button class="btn bg-white" @click="$router.push({ query: undefined })">
                clear search
              </button>
            </div>
          </div>
          <div v-for="group in populatedPricesInGroups" :key="group.id">
            <h1
              class="text-center text-base p-2 w-full bg-green-600 text-white"
              :class="{ 'sticky top-0 z-10': active_section === group.id }"
              :data-pricing-group="group.id"
            >
              {{ group.description }}
            </h1>
            <table class="table-pricing mb-6">
              <price-line
                v-for="price in group.prices"
                :key="price.id"
                :price="price"
                @price-updated="priceUpdated(price, $event)"
              ></price-line>
            </table>
            <div v-if="group.description === 'Composite Door'" class="flex m-3">
              <div class="mr-3">
                <div
                  class="btn-info"
                  @click.prevent="
                    (pricing_info_popup_starting_section = 'Composite Door Colours'),
                    (pricing_info_popup_open = true)"
                >
                  View Composite Door Colours <i class="fa fa-info-circle"></i>
                </div>
              </div>
              <div class="mr-3">
                <div
                  class="btn-info"
                  @click.prevent="
                    (pricing_info_popup_starting_section = 'Doors'),
                    (pricing_info_popup_open = true)"
                >
                  View Door Styles <i class="fa fa-info-circle"></i>
                </div>
              </div>
              <div class="">
                <div
                  class="btn-info"
                  @click.prevent="
                    (pricing_info_popup_starting_section = 'Composite Door Glass'),
                    (pricing_info_popup_open = true)"
                >
                  View Glass Styles <i class="fa fa-info-circle"></i>
                </div>
              </div>
              <portal v-if="pricing_info_popup_open" to="portal_pricing_info_popup">
                <modal-window
                  :modal_open="pricing_info_popup_open"
                  :max-width="1550"
                  title=""
                  @close="pricing_info_popup_open = false"
                >
                  <pricing-info-popup
                    :start_on_section="pricing_info_popup_starting_section"
                    @close="pricing_info_popup_open = false"
                  ></pricing-info-popup>
                </modal-window>
              </portal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PriceLine from '@/components/shared/PriceLine.vue';
import PricingInfo from '@/components/shared/PricingInfo.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    'price-line': PriceLine,
    'pricing-info-popup': PricingInfo,
    SearchBox,
  },
  props: {
    groups: Array,
    anomalies: Array,
    original_anomalies: Array
  },
  data() {
    return {
      pricing_info_popup_open: false,
      pricing_info_popup_starting_section: null,
      unpricedFilterActive: false,
      filters: [
        {
          label: 'PVC',
          filter: this.filterPVC,
          active: true,
        },
        {
          label: 'Aluminium',
          filter: this.filterAluminium,
          active: true,
        },
        {
          label: 'Timber',
          filter: this.filterTimber,
          active: true,
        },
        {
          label: 'Other',
          filter: this.filterOther,
          active: true,
        },
      ],
      active_section: null,
      category_list_open: false,
    };
  },
  computed: {
    filter() {
      return this.$route.query.search || '';
    },
    filteredAnomalies() {
      let filteredAnomalies = this.anomalies;
      if (this.filter) {
        const allFilters = [...this.filter.matchAll(/(?:[^\s"']+|["]([^"]*)["])+/g)].map(
          (result) => (result[1] !== undefined ? result[1] : result[0]),
        );
        if (allFilters.length > 0) {
          filteredAnomalies = this.anomalies.filter((anomaly) =>
            allFilters.some((filter) => {
              if (filter.startsWith('!')) {
                return false;
              }

              if (filter.startsWith('group:')) {
                return anomaly.groupText.toLowerCase().includes(filter.substr(6).toLowerCase());
              }
              return anomaly.description.toLowerCase().includes(filter.toLowerCase());
            }),
          );

          filteredAnomalies = filteredAnomalies.filter(
            (anomaly) =>
              !allFilters.some((filter) => {
                if (!filter.startsWith('!')) {
                  return false;
                }

                if (filter.startsWith('!group:')) {
                  return anomaly.groupText.toLowerCase().includes(filter.substr(7).toLowerCase());
                }

                return anomaly.description.toLowerCase().includes(filter.substr(1).toLowerCase());
              }),
          );
        }
      }

      const activeFilters = this.filters.filter((filter) => !filter.active);

      if (activeFilters.length !== this.filters.length) {
        activeFilters.forEach((activeFilter) => {
          filteredAnomalies = filteredAnomalies.filter(activeFilter.filter);
        });
      }

      if (this.unpricedFilterActive) {
        // eslint-disable-next-line eqeqeq
        filteredAnomalies = filteredAnomalies.filter(
          (anomaly) =>
            this.original_anomalies.findIndex(
              (originalAnomaly) =>
                originalAnomaly.id === anomaly.id && originalAnomaly.overrideValue == 0,
            ) !== -1,
        );
      }

      return filteredAnomalies;
    },
    pricesInGroups() {
      return this.groups.map((group) => ({
        ...group,
        prices: this.filteredAnomalies.filter((anomaly) => anomaly.groupId === group.id),
      }));
    },
    populatedPricesInGroups() {
      return this.pricesInGroups.filter((group) => group.prices.length > 0);
    },
  },
  async mounted() {
    this.active_section = this.pricesInGroups[0].id;
  },
  methods: {
    scrollToTop() {
      document.getElementById('pricing_index_scrolling_outer_wrapper').scrollTop = 0;
    },
    filterPVC(anomaly) {
      return !anomaly.description.includes('PVC');
    },
    filterAluminium(anomaly) {
      return !anomaly.description.includes('Aluminium');
    },
    filterTimber(anomaly) {
      return !anomaly.description.includes('Timber');
    },
    filterOther(anomaly) {
      return (
        !this.filterPVC(anomaly) || !this.filterAluminium(anomaly) || !this.filterTimber(anomaly)
      );
    },
    productListScrolled(wrapper) {
      const scrolledAmount = wrapper.srcElement.scrollTop;
      document.querySelectorAll('[data-pricing-group]').forEach((pricingGroup) => {
        if (scrolledAmount >= pricingGroup.offsetTop) {
          this.active_section = pricingGroup.dataset.pricingGroup;
        }
      });
    },
    priceUpdated(price, object) {
      const modifiedPrice = this.anomalies.find((anomaly) => anomaly.id === price.id);
      modifiedPrice.valueIsNett = object.valueIsNett;
      modifiedPrice.overrideValue = object.overrideValue;
    },
  },
};
</script>
