export default {
  namespaced: true,
  actions: {
    async marketingCategories() {
      return window.touch.marketingCategories();
    },
    async marketingItems(context, options) {
      return window.touch.marketingItems(options);
    },
    async documentDetails(context, id) {
      return window.touch.documentDetails(id);
    },
    async uploadDocumentForm(context, document) {
      return window.touch.uploadDocumentForm(document);
    },
    async uploadVideo(context, document) {
      return window.touch.customerUploadDocumentWithUrl(document);
    },
    async addDocumentCategory(context, categoryName) {
      return window.touch.addDocumentCategory(categoryName);
    },
    async updateDocumentCategory(context, category) {
      return window.touch.updateDocumentCategory(category);
    },
    async deleteDocumentCategories(context, categoryIds) {
      return window.touch.deleteDocumentCategories(categoryIds);
    },
    async deleteMarketingDocuments(context, documentIds) {
      return window.touch.deleteMarketingDocuments(documentIds);
    },
    async getDocumentThumbnail(context, id) {
      return window.touch.displayStream(`document/GetThumbnail/${id}`);
    },
    async downloadDocument(context, id) {
      return window.touch.download(`document/Get/${id}`);
    },
  },
};
