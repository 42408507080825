var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full border-t"},[_c('div',{staticClass:"bg-white overflow-x-auto pl-3 md:pl-10"},[_c('div',{staticClass:"flex bg-white gap-10 border-t-4 border-white"},[(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.$route.meta.active === 'pricing',
          'border-white': _vm.$route.meta.active !== 'pricing',
        },attrs:{"to":"/product-setup/pricing"}},[_vm._v(" Pricing ")]):_vm._e(),(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.$route.meta.active === 'financial-categories',
          'border-white': _vm.$route.meta.active !== 'financial-categories',
        },attrs:{"to":"/product-setup/pricing/financial-categories"}},[_vm._v(" Financial Categories ")]):_vm._e(),(!_vm.isLeadGen)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.$route.meta.active === 'discounts',
          'border-white': _vm.$route.meta.active !== 'discounts',
        },attrs:{"to":"/product-setup/pricing/discounts"}},[_vm._v(" Discounts ")]):_vm._e(),_c('div',{staticClass:"pr-5"})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }