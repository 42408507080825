<template>
  <router-view>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Product Setup', '/product-setup'],
          ['Pricing'],
        ]"
      />
    </template>
    <template #header_page_title> Pricing </template>
    <template #menu> 
      <Menu></Menu>
      <SubMenu></SubMenu>
    </template>
  </router-view>
</template>

<script>
import Menu from '@/components/business/productSetup/Menu.vue';
import SubMenu from '@/components/business/productSetup//pricing/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Menu,
    Breadcrumbs,
    SubMenu
  }
};
</script>
