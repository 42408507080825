import { errorCallback, successCallback } from '@/helpers/validation';
import Touch from './touch';

Touch.prototype.getAllCompanyHolidays = async function getAllCompanyHolidays() {
  return (await this.authenticatedPost('company/GetCompanyHolidays')).data;
};

Touch.prototype.addCompanyHoliday = async function addCompanyHoliday(title, dateStart, dateEnd) {
  this.parseResponse(
    await this.authenticatedPost('company/AddCompanyHoliday', {
      Title: title,
      DateStart: dateStart,
      DateEnd: dateEnd,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.updateCompanyHoliday = async function updateCompanyHoliday(
  id,
  title,
  dateStart,
  dateEnd,
) {
  this.parseResponse(
    await this.authenticatedPost('company/UpdateCompanyHoliday', {
      Id: id,
      Title: title,
      DateStart: dateStart,
      DateEnd: dateEnd,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.deleteCompanyHoliday = async function deleteCompanyHoliday(id) {
  this.parseResponse(
    await this.authenticatedPost('company/DeleteCompanyHoliday', { Id: id }),
    errorCallback,
    () => successCallback('Delete'),
  );
};

Touch.prototype.getUserDetails = async function getUserDetails() {
  return (await this.authenticatedPost('company/GetUserDetails')).data;
};

Touch.prototype.getListCompanies = async function getListCompanies(CompanyType) {
  return this.parseResponse(
    await this.authenticatedPost('company/ListCompanies', {
      CompanyType,
    }),
    errorCallback,
  );
};

Touch.prototype.GetOrganisation = async function GetOrganisation(companyId) {
  return this.parseResponse(await this.authenticatedPost('company/GetOrganisation', { companyId }));
};

Touch.prototype.CreateOrganisation = async function CreateOrganisation(
  CompanyId,
  FittingMarkup,
  SupplierCost,
  SupplierMarkup,
) {
  return this.parseResponse(
    await this.authenticatedPost('company/CreateOrganisation', {
      CompanyId,
      FittingMarkup,
      SupplierCost,
      SupplierMarkup,
    }),
  );
};

Touch.prototype.UpdateOrganisation = async function UpdateOrganisation({
  Id,
  CompanyId,
  FittingMarkup,
  SurveyCost,
  SurveyMarkup,
  SendBccEmail,
  BccEmailAddress
}) {
  return this.parseResponse(
    await this.authenticatedPost('company/UpdateOrganisation', {
      Id,
      CompanyId,
      FittingMarkup,
      SurveyCost,
      SurveyMarkup,
      SendBccEmail,
      BccEmailAddress
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};
