<template>
  <div>
    <hr class="-mx-10">
    <div class="flex flex-wrap-reverse justify-between items-center m-3 xs:m-10 mb-0">
      <div class="text-2xl font-medium">
        Account Settings
      </div>
    </div>
    <ApproveOrderWarnings></ApproveOrderWarnings>
    <BccOrderConfirmation :organisation="organisation" @getOrganisation="getOrganisation"></BccOrderConfirmation>
    <div v-if="!isLeadGen && $store.state.auth.accountSettings.showFitting">
      <FittingAndSurvey :organisation="organisation" @getOrganisation="getOrganisation"></FittingAndSurvey>
    </div>
  </div>
</template>

<script>
import ApproveOrderWarnings from '@/components/business/manageTouchAccount/accountSettings/ApproveOrderWarnings.vue';
import BccOrderConfirmation from '@/components/business/manageTouchAccount/accountSettings/BccOrderConfirmation.vue';
import FittingAndSurvey from '@/components/business/manageTouchAccount/accountSettings/FittingAndSurvey.vue';
import { mapMutations } from 'vuex';

export default {
  components: {
    ApproveOrderWarnings,
    BccOrderConfirmation,
    FittingAndSurvey
  },
  props: {
    isLeadGen: Boolean
  },
  data() {
    return {
      organisation: {}
    }
  },
  async created() {
    await this.getOrganisation();
  },
  methods: {
    async getOrganisation() {
      this.organisation = await window.touch.GetOrganisation(
        this.$store.state.user.customer.companyId,
      );
    },
  }
};
</script>